export const SEO = {
  TITLE_META: `STRATO Mercata Marketplace`,
  DESCRIPTION_META: `Join the STRATO Mercata marketplace for buying, selling, and investing in  
    fractionalized assets. Expand your collection or find buyers for your rare carbon,  
    metals, art, collectibles, and clothing.`,
  IMAGE_META: `STRATO Mercata Marketplace Banner`,
  SEO_ROUTES:[
    "/",
    "/c/All",
    "/c/Carbon?sc=CarbonOffset%2CCarbonDAO",
    "/c/Metals?sc=Metals",
    "/c/Clothing?sc=Clothing",
    "/c/Collectibles?sc=Collectibles",
    "/c/Art?sc=Art",
    "/c/Membership?sc=Membership",
    "/checkout",
  ]
}