import { Extension } from "@tiptap/react";
import { Node } from '@tiptap/core';

export class BlockquoteNode extends Node {
  get name() {
    return 'blockquote';
  }

  get schema() {
    return {
      content: 'block+',
      defining: true,
      parseDOM: [{ tag: 'blockquote' }],
      toDOM: () => ['blockquote', 0],
    };
  }
}

export class UnorderedListNode extends Node {
  get name() {
    return 'bulletList';
  }

  get schema() {
    return {
      content: 'listItem+',
      group: 'block',
      parseDOM: [{ tag: 'ul' }],
      toDOM: () => ['ul', 0],
    };
  }
}

export class OrderedListNode extends Node {
  get name() {
    return 'orderedList';
  }

  get schema() {
    return {
      content: 'listItem+',
      group: 'block',
      parseDOM: [{ tag: 'ol' }],
      toDOM: () => ['ol', 0],
    };
  }
}

export const FontSize = Extension.create({
    name: "fontSize",
  
    addGlobalAttributes() {
      return [
        {
          types: ["textStyle"],
          attributes: {
            fontSize: {
              default: null,
              parseHTML: (element) => element.style.fontSize,
              renderHTML: (attributes) => {
                if (!attributes.fontSize) {
                  return {};
                }
                return { style: `font-size: ${attributes.fontSize}` };
              },
            },
          },
        },
      ];
    },
  
    addCommands() {
      return {
        setFontSize:
          (fontSize) =>
          ({ chain }) => {
            return chain().setMark("textStyle", { fontSize }).run();
          },
        unsetFontSize:
          () =>
          ({ chain }) => {
            return chain()
              .setMark("textStyle", { fontSize: null })
              .removeEmptyTextStyle()
              .run();
          },
      };
    },
  });
  
export const FontFamily = Extension.create({
    name: "fontFamily",
  
    addGlobalAttributes() {
      return [
        {
          types: ["textStyle"],
          attributes: {
            fontFamily: {
              default: null,
              parseHTML: (element) => element.style.fontFamily,
              renderHTML: (attributes) => {
                if (!attributes.fontFamily) {
                  return {};
                }
                return { style: `font-family: ${attributes.fontFamily}` };
              },
            },
          },
        },
      ];
    },
  
    addCommands() {
      return {
        setFontFamily:
          (fontFamily) =>
          ({ chain }) => {
            return chain().setMark("textStyle", { fontFamily }).run();
          },
        unsetFontFamily:
          () =>
          ({ chain }) => {
            return chain()
              .setMark("textStyle", { fontFamily: null })
              .removeEmptyTextStyle()
              .run();
          },
      };
    },
  });